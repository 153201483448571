$(() => {
  if (!$('.js-multi-email-input').length) {
    return;
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // メールアドレスの正規表現

  let setupComponent = (element) => {
    let $el = $(element);
    $el.tagsinput();

    // メールアドレスのみ許可
    $el.on('beforeItemAdd', function(event) {
      if (!emailRegex.test(event.item)) {
        event.cancel = true; // 無効な場合は追加をキャンセル
        alert("正しいメールアドレスを入力してください");
      }
    });
  };

  $('.js-multi-email-input').each((i, element) => {
    setupComponent(element);
  });
});
