$(() => {
  if (!$('.js-range-search-date').length) {
    return;
  }

  $('.js-range-search-date input[type="date"]').each(function () {
    let $input = $(this);

    function updateColor() {
      $input.toggleClass('empty', !$input.val());
    }

    $input.on("change input", updateColor);
    updateColor();
  });
});